<template>
  <v-card class="elevation-12">
    <template v-if="showLogin">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>{{ $t("login.form.title") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form @keyup.native.enter="login">
          <v-text-field :label="$t('login.form.username')" v-model="username" prepend-icon="mdi-account" type="text" />
          <v-text-field :label="$t('login.form.password')" v-model="password" prepend-icon="mdi-lock" type="password" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="showLogin = !showLogin">{{ $t("login.form.resetPwd") }}</v-btn>
        <v-spacer />
        <v-btn color="lime" @click="login()">{{ $t("login.form.submit") }}</v-btn>
      </v-card-actions>
    </template>
    <template v-else>
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>{{ $t("login.form.title") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form @keyup.native.enter="login">
          <v-text-field :label="$t('login.form.resetemail')" v-model="resetmail" prepend-icon="mdi-account" type="text" />
          {{ $t("login.form.resetInfo") }}
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="showLogin = !showLogin">{{ $t("login.form.cancel") }}</v-btn>
        <v-spacer />
        <v-btn color="lime" @click="submitReset()">{{ $t("login.form.submitreset") }}</v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import axios from "axios";
import { setDark } from "@/services/theme";
import { startLogoutTimer } from "../../helpers/timeout.js";

import versions from "@/helpers/versions.json";

export default {
  data() {
    return {
      showLogin: true,
      username: "",
      password: "",
      resetmail: "",
    };
  },
  computed: {},
  methods: {
    login() {
      this.$store
        .dispatch("login", {
          username: this.username,
          password: this.password,
        })
        .then((u) => {
          this.$root.$i18n.locale = u.lang;
          let redirectUrl = "/";

          if (this.$route.query.redirect) {
            redirectUrl = this.$route.query.redirect;
          }

          if (u.role == "calview") {
            redirectUrl = "/calendars";
          }

          if (!u.profile.hideReleaseNotesPopup && (!u.profile.seenVersion || u.profile.seenVersion < versions[0].num)) {
            redirectUrl = `/releasenotes?returnurl=${redirectUrl}`;
          }

          console.log("Dark?", u.profile.useDarkTheme);

          setDark(u.profile.useDarkTheme || false);

          startLogoutTimer();

          this.$router.push(redirectUrl);
        })
        .catch((error) => {
          let err = {
            message: "Undefined Error",
            color: "red",
          };
          if (error.data && error.data.message) {
            err.message = error.data.message;
          }

          this.$store.dispatch("showSnackMessage", err);
        });
    },
    submitReset() {
      axios
        .get(`/api/users/sendpwdresetrequest?email=${this.resetmail}`)
        .then(() => {
          this.$store.dispatch("showSnackMessage", { message: "Password reset request has been sent." });
        })
        .catch(() => {
          this.$store.dispatch("showSnackMessage", { message: "Error sending mail. Please try again." });
        });
      this.resetmail = "";
      this.showLogin = true;
    },
  },
};
</script>
